export default {
    state: {
        camera:{
            camera_capture:false,
            open_camera:false,
            aspectRatio:[
                {
                    text:'16:9',
                    width:1280,
                    height:720,
                },
                {
                    text:'4:3',
                    width:640,
                    height:480,
                },
                {
                    text:'1:1',
                    width:720,
                    height:720,
                }
            ]
        },
        camera_image_lists:[],
        file_items:[]
    },
    getters: {
        GET_CAMERA_CAPTURE:(state)=>state.camera.camera_capture,
        GET_OPEN_CAMERA:(state)=>state.camera.open_camera,
        GET_CAMERA_STATE:(state)=>state.camera,
        GET_CAMERA_IMAGE_LISTS:(state)=>state.camera_image_lists,
        GET_FILE_ITEMS:(state)=>state.file_items
    },
    mutations: {
        CAMERA_CAPTURE:(state,data)=>{
            state.camera.camera_capture = data
        },
        OPEN_CAMERA:(state,data)=>{
            state.camera.open_camera = data
        },
        CAMERA_IMAGE_LISTS:(state,data)=>{
            state.camera_image_lists = data
        },
        FILE_ITEMS:(state,data)=>{
            state.file_items = data
        }
    },
    actions: {
        cameraInit({commit},params){
            if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
                navigator.mediaDevices.getUserMedia(params.constraints).then(stream=>{
                    params.videoPlayer.srcObject = stream;
                    params.videoPlayer.play();

                    commit('OPEN_CAMERA',true);
                })
            }
        },
        cameraStop({commit},params){
            if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){
                navigator.mediaDevices.getUserMedia(params.constraints).then(stream=>{
                    const tracks = stream.getTracks();
                    // console.log(tracks[0]);
                      tracks[0].stop;
                    commit('OPEN_CAMERA',false);
                })
            }
        },
        insertImage({commit},params){
            commit('CAMERA_IMAGE_LISTS',params);
        }
    },
  };
  