<template>
    <v-app>
     <v-container bg fill-height grid-list-md text-xs-center>
       <v-layout row wrap align-center>
         <v-flex>
           <v-card
                class="mx-auto"
                max-width="450"
                elevation="20"
                bg
                fill-height
                grid-list-md
                text-xs-center
           >
           <v-toolbar
                elevation="2"
               class="ma-0 pa-0"
               prominent
               src="@/assets/ingcoph_bg/ingcoph_login.jpg"
            >
            </v-toolbar>
            <v-card-title>
                <span>Enter security code</span>
            </v-card-title>
            <v-divider class="m-0"></v-divider>
            <v-form>
            <v-card-text>
                    <v-row class="pa-3">
                        <span>Please check your {{ getVia() }} for a message containing your verification code. The code is 6 digits long. Ensure you enter it correctly to complete the process</span>
                    </v-row>
                    <v-row class="pa-3">
                        <span>We sent your code to: {{ maskEmail(GET_EMAIL_USERNAME) }}</span>
                    </v-row>
                    <v-row class="pa-3">
                        <v-col cols="10">
                            <v-otp-input
                                v-model="otp"
                                id="otp"
                                length="6"
                                type="number"
                            ></v-otp-input>
                        </v-col>
                        <span v-if="error" style="color:red">{{ error }}</span>
                        <v-spacer />
                    </v-row>
            </v-card-text>
            <v-divider class="ma-0"></v-divider>
            <v-card-actions class="py-10">
                <v-row>
                    <v-col cols="6">
                        <v-btn
                            @click="didntCode()"
                            class="ma-2 pa-2"
                            color="blue"
                            text
                            >
                            Didn't get a code?
                        </v-btn>
                    </v-col>
                    <v-spacer />
                    <v-col cols="6">
                        <v-btn
                            @click="cancel()"
                            class="ma-2 pa-2 pr-3 pl-3"
                            elevation="2"
                            text
                        >
                            CANCEL
                        </v-btn>
                        <v-btn
                        :loading="loading"
                        @click="submitCode()"
                        class="ma-2 pa-2 pl-2 pr-2"
                        elevation="2"
                        >
                            CONTINUE
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            </v-form>
         </v-card>
         </v-flex>
       </v-layout>
     </v-container>
   </v-app>
 </template>

 <script>
import { mapGetters } from 'vuex';

 export default {
     data(){
         return {
             loading: false,
             valid: true,
             error: '',
             otp: null,
         };
     },
     methods: {
        submitCode(){
            this.loading = true
            let payload = {
                code: this.otp,
                username:this.GET_EMAIL_USERNAME.username
            }
            if (this.otp == null) {
                this.error = ' Security Code is required.'
                this.loading = false;
            }
            else {
                this.$store.dispatch('sendCode', payload).then(success => {
                    console.log(success)
                    if (success.code == 200) {
                        console.log(success)
                        this.loading = false;
                        this.$router.push({ path: '/resetpassword' }).catch((err) => {
                            this.loading = false;
                        });
                    }
                }).catch((error) => {
                    this.error = error.response.data.message;
                    this.loading = false;
                });
            }
        },
        didntCode(){
            this.$router.push({ path: '/confirmresetpassword' }).catch((err) => {
                this.loading = false;
            });
        },
        cancel(){
                 this.loading = false;
                 let payload = {
                    username:this.GET_EMAIL_USERNAME.username,
                    cancel: 1
                }
                this.$store.dispatch('sendCode', payload).then(success => {
                    this.$router.push({ path: '/' }).catch((err) => {
                        this.loading = false;
                    });
                }).catch((error) => {
                    this.error = error.response.data.message;
                    this.loading = false;
                });
         },
        maskEmail(value) {
            let via = localStorage.getItem('send_via');
            const parts = value[via]?.split('@');
            const username = parts ? parts[0] : value;
            const domain = parts ? parts[1] : value;
            const maskedUsername = (parts.length > 1 ? username.substr(0, 2)  + '*'.repeat(username.length - 2) : username.substr(0, 6)  + '*'.repeat(username.length - 6));
            const maskedEmail = parts.length > 1 ? maskedUsername + '@' + domain : maskedUsername;
            return maskedEmail;
        },
        getVia(){
            switch(localStorage.getItem('send_via')){
                case 'mobile':
                    return 'registered mobile number'
                case 'email':
                    return 'registered email address' 

            }
        }
     },
     computed: {
        ...mapGetters([
            "GET_EMAIL_USERNAME",
            // "GET_OTP_CODE"
        ]),
     },
     watch: {
     },

 }
 </script>

 <style scoped>

 </style>
