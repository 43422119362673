import repository from "@/api/modules/Admin/adminAssetTurnover";

export default {
    state: {
        adminAssetTurnoverDataTableReload: false,
        adminAssetTurnoverExport: false,
        adminAssetTurnoverViewId: undefined,
        adminAssetTurnovers: [],
    },
    getters: {
        GET_ADMIN_ASSET_TURNOVER_DATA_TABLE_RELOAD: (state) =>
            state.adminAssetTurnoverDataTableReload,
        GET_ADMIN_ASSET_TURNOVER_EXPORT: (state) =>
            state.adminAssetTurnoverExport,
        GET_ADMIN_ASSET_TURNOVER_VIEW_ID: (state) =>
            state.adminAssetTurnoverViewId,
        GET_ADMIN_ASSET_TURNOVERS: (state) =>
            state.adminAssetTurnovers,
    },
    actions: {
        adminAssetTurnoverGet({}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .adminAssetTurnoverGet(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        adminAssetTurnoverPost({}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .adminAssetTurnoverPost(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        adminAssetTurnoverPut({}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .adminAssetTurnoverPut(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        adminAssetTurnoverDelete({}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .adminAssetTurnoverDelete(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getAdminAssetTurnoverItemFile({}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .getAdminAssetTurnoverItemFile(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        deleteAdminAssetTurnoverItemFile({}, payload) {
            return new Promise((resolve, reject) => {
                repository
                    .deleteAdminAssetTurnoverItemFile(payload)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        printTurnOverForm({}, payload) {
            return new Promise((resolve,reject) => {
				repository.printTurnOverForm(payload).then((response) => {
					resolve(response.data);
				}).catch((error) => {
					reject(error);
				});
			})
        },
        exportTurnOvers({}, payload) {
            return new Promise((resolve,reject) => {
				repository.exportTurnOvers(payload).then((response) => {
					resolve(response.data);
				}).catch((error) => {
					reject(error);
				});
			})
        },
        massApproveTurnOver({}, payload) {
            return new Promise((resolve,reject) => {
				repository.massApproveTurnOver(payload).then((response) => {
					resolve(response.data);
				}).catch((error) => {
					reject(error);
				});
			})
        },
    },
    mutations: {
        ADMIN_ASSET_TURNOVER_DATA_TABLE_RELOAD: (state, data) => {
            state.adminAssetTurnoverDataTableReload = data;
        },
        ADMIN_ASSET_TURNOVER_EXPORT: (state, data) => {
            state.adminAssetTurnoverExport = data;
        },
        ADMIN_ASSET_TURNOVER_VIEW_ID: (state, data) => {
            state.adminAssetTurnoverViewId = data;
        },
        ADMIN_ASSET_TURNOVERS: (state, data) => {
            state.adminAssetTurnovers = data;
        },
    },
};
