var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{attrs:{"bg":"","fill-height":"","grid-list-md":"","text-xs-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"450","elevation":"20","bg":"","fill-height":"","grid-list-md":"","text-xs-center":""}},[_c('v-toolbar',{staticClass:"ma-0 pa-0",attrs:{"elevation":"2","prominent":"","src":require("@/assets/ingcoph_bg/ingcoph_login.jpg")}}),_c('v-card-title',[_c('span',[_vm._v("Reset your password")])]),_c('v-divider',{staticClass:"m-0"}),_c('v-card-text',[_c('v-row',{staticClass:"pa-2"},[_c('v-col',{attrs:{"cols":"9"}},[_c('span',[_vm._v("How do you want to get the code to reset your password?")]),_c('v-radio-group',{attrs:{"label":"Send code via?"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-radio',{staticClass:"mt-3 mb-3",attrs:{"value":{
                                 type:'email',
                                 value:_vm.GET_EMAIL_USERNAME.email
                             },"name":"email","disabled":_vm.maskEmail(_vm.GET_EMAIL_USERNAME.email) == 'No email available'?true:false,"label":_vm.maskEmail(_vm.GET_EMAIL_USERNAME.email)}}),_c('v-radio',{staticClass:"mt-3 mb-3",attrs:{"value":{
                                 type:'mobile',
                                 value:_vm.GET_EMAIL_USERNAME.mobile
                             },"name":"sms","disabled":_vm.maskEmail(_vm.GET_EMAIL_USERNAME.mobile) == 'No mobile number available'?true:false,"label":_vm.maskEmail(_vm.GET_EMAIL_USERNAME.mobile)}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"align":"center"}},[_c('v-icon',{staticStyle:{"font-size":"70px"}},[_vm._v("mdi-account-circle")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.GET_EMAIL_USERNAME.username)+" ")])],1)],1)],1)],1),_c('v-divider',{staticClass:"ma-0"}),_c('v-card-actions',{staticClass:"py-10"},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"pr-3 pl-3",attrs:{"elevation":"2","text":""},on:{"click":function($event){return _vm.notYou()}}},[_vm._v(" NOT YOU? ")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"pr-2 pl-2",attrs:{"loading":_vm.loading,"type":"submit","elevation":"2"},on:{"click":function($event){return _vm.resetSubmit()}}},[_vm._v(" CONTINUE ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }