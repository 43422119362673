import store from '../../../store';
import api from '../../api'

export default {
    createFasBasket(params){
       return api.post('/admin/fasbasket',params)
    },
    updateFasBasket(params){
        return api.put(`/admin/fasbasket/${params.id}`,params)
     },
     deleteFasBasket(params){
        return api.delete(`/admin/fasbasket/${params}`)
     },
     getAllFasBasket(params){
        return api.get('/admin/getAllfasbasket',{params})
    },
    showFasBasket(params){
        return api.get('/admin/fasbasket/'+params.id,{params})
    },
    saveImportPrice(params){
        return api.post('/admin/fasbasket/saveImportPrice',params)
    },
    getItemBasketCanvassing(params){
        return api.post('/admin/fasbasket/getItemBasketCanvassing',params)
    },
    approveAllFasBasket(params){
        return api.post('/admin/fasbasket/approveAllFasBasket',params)
    },
    getAllVendorPriority(params){
        return api.get('/admin/getAllVendorPriority', params)
    },
    updateAllVendorPriority(params){
        return api.post('/admin/updateAllVendorPriority', params)
    },
    getSelectedItemBasketCanvassing(params){
        return api.post('/admin/getSelectedItemBasketCanvassing', params)
    },
    purchaseBasketGet(params){
        return api.get(`/admin/${params.url}`,{params})
    },
    purchaseBasketPost(params){
        return api.post(`/admin/${params.url}`, params)
    },
}

