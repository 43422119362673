import dashboard from "@/store/modules/service/dashboard";
import api from "../../api";
export default {
    newuser(params) {
        return api.post("masterdata/newuser", params);
    },
    userslist() {
        return api.get("dropdown/users-list");
    },
    userslistTable(params) {
        return api.get("masterdata/users-list", {params});
    },
    adddepartment(params) {
        return api.post("masterdata/add-department", params);
    },
    accesscontrol(params) {
        return api.post("masterdata/access-control", params);
    },
    edituser(params) {
        return api.put("masterdata/edit-user", params);
    },
    updatedepartment(params) {
        return api.put("masterdata/update-department", params);
    },
    managers() {
        return api.get("dropdown/getManagers");
    },
    departmentall(params) {
        return api.get("masterdata/department-all",{params});
    },
    departmentlist(params) {
        return api.get("dropdown/department-list", {params});
    },
    departmentaccesslist() {
        return api.get("masterdata/departmentaccess-list");
    },
    departmentroles(params) {
        return api.get("masterdata/department-roles-list",{params});
    },
    hasaccess(params) {
        return api.get("masterdata/roles-hasaccess", params);
    },
    roleaccess(params) {
        return api.get("masterdata/access-role", { params });
    },
    addAccessControl(params) {
        return api.post("masterdata/access-control-role", params);
    },
    getAccessControl(params) {
        return api.get(`masterdata/access-control-list/${params}`);
    },
    deleteuser(params) {
        return api.delete(`masterdata/delete-user/${params}`);
    },
    deletedepartment(params) {
        return api.delete(`masterdata/delete-department/${params}`);
    },
    vehicleslist(params) {
        return api.get("/dropdown/vehicles-list", { params });
    },
    addvehicle(params) {
        return api.post("/masterdata/add-vehicles", params);
    },
    updatevehicles(params) {
        return api.put("masterdata/update-vehicles", params);
    },
    deletevehicles(params) {
        return api.delete(`masterdata/delete-vehicles/${params}`);
    },
    warehouselist(params) {
        return api.get("masterdata/warehouse-list", {params});
    },
    addwarehouse(params) {
        return api.post("masterdata/add-warehouse", params);
    },
    addwarehouseitems(params) {
        return api.post("masterdata/add-items-on-warehouse", params);
    },
    updatewarehouse(params) {
        return api.put("masterdata/update-warehouse", params);
    },
    deletewarehouse(params) {
        return api.delete(`masterdata/delete-warehouse/${params}`);
    },
    salesmanposition() {
        return api.get("masterdata/salesmanposition-list");
    },
    salesman() {
        return api.get("masterdata/salesman-list");
    },
    addsalesmanposition(params) {
        return api.post("masterdata/add-salesmanposition", params);
    },
    updateallsalesmanposition(params) {
        return api.put("masterdata/updateAllSalesmanPositions", params);
    },
    updatesalesmanpositions(params) {
        return api.put("masterdata/update-salesmanposition", params);
    },
    deletesalesmanposition(params) {
        return api.delete(`masterdata/delete-salesmanposition/${params}`);
    },
    addsalesman(params) {
        return api.post("masterdata/add-salesman", params);
    },
    updatesalesman(params) {
        return api.put("masterdata/update-salesman", params);
    },
    deletesalesman(params) {
        return api.delete(`masterdata/delete-salesman/${params}`);
    },
    customers(params) {
        return api.get("masterdata/customers-list", { params: params });
    },
    addcustomers(params) {
        return api.post("masterdata/add-customerinfo", params);
    },
    updatecustomers(params) {
        return api.put("masterdata/update-customerinfo", params);
    },
    deletecustomer(params) {
        return api.put(`masterdata/delete-customerinfo/${params.id}`, params);
    },
    classifications(params) {
        return api.get("masterdata/classifications-list", { params: params });
    },
    addclassifications(params) {
        return api.post("masterdata/addclassification", params);
    },
    updateclassifications(params) {
        return api.put(`masterdata/updateclassification/${params.id}`, params);
    },
    deleteclassifications(params) {
        return api.delete(`masterdata/deleteclassification/${params}`);
    },
    addshippingaddress(params) {
        return api.post("masterdata/addshipping-address", params);
    },
    addbillingaddress(params) {
        return api.post("masterdata/addbilling-address", params);
    },
    addCustomerCluster(params) {
        return api.post("masterdata/add-customer-cluster", params);
    },
    adddiscount(params) {
        return api.post("masterdata/add-discount", params);
    },
    discounttype() {
        return api.get("dropdown/discounttype-list");
    },
    vendor(params) {
        return api.get("masterdata/vendor-list", { params: params });
    },
    addvendor(params) {
        return api.post("masterdata/add-vendor", params);
    },
    addvendoraddress(params) {
        return api.post("masterdata/add-vendoraddress", params);
    },
    editvendor(params) {
        return api.post("masterdata/edit-vendor", params);
    },
    deletevendor(params) {
        return api.put("masterdata/delete-vendor", params);
    },
    vendortype() {
        return api.get("masterdata/vendortype-list");
    },
    addvendortype(params) {
        return api.post("masterdata/add-vendortype", params);
    },
    editvendortype(params) {
        return api.put("masterdata/edit-vendortype", params);
    },
    deletevendortype(params) {
        return api.delete(`masterdata/delete-vendortype/${params}`);
    },
    getallwarehouses() {
        return api.get("masterdata/getWarehouses");
    },
    postgetcustomers(params) {
        return api.post("masterdata/postGetCustomers", params);
    },
    getpackinglist(params) {
        return api.get("masterdata/getPackingLists", params);
    },
    getcustomeraddress(id, params) {
        return api.get(`masterdata/getCustomerAddresses/${id}`, { params });
    },
    showcustomers(id) {
        return api.get(`masterdata/customers/${id}`);
    },
    getcustomers(params) {
        return api.get("dropdown/getCustomers", { params });
    },
    getsalesmanlist() {
        return api.get("masterdata/getSalesmanList");
    },
    getcurrentsalesman() {
        return api.get("masterdata/salesman/getCurrentSalesman");
    },
    getgentradecustomers(params) {
        return api.get(`dropdown/getGenTradeCustomers`, { params });
    },
    confirmoverrideprintpl(params) {
        return api.post(`masterdata/confirmOverridePrintPL`, params);
    },
    confirmoverride(params) {
        return api.post(`masterdata/confirmOverride`, params);
    },
    checkIfManager(params) {
        return api.get("masterdata/" + params.url);
    },
    usergetadmin() {
        return api.get("masterdata/userGetManagerAdmin");
    },
    postChangePassword(params) {
        return api.post("masterdata/postChangePassword", params);
    },
    CustomerCategory() {
        return api.get("masterdata/CustomerCategory-list");
    },
    addCustomerCategory(params) {
        return api.post("masterdata/addcustomer-category", params);
    },
    updateCustomerCategory(params) {
        return api.put("masterdata/updatecustomer-category", params);
    },
    deleteCustomerCategory(params) {
        return api.delete(`masterdata/deletecustomer-category/${params}`);
    },
    exportCustomer(params) {
        return api.post(`masterdata/exportCustomer`,params);
    },
    getcustomerclassificationslist(params){
        return api.get(`masterdata/getCustomerClassificationsList`);
    },
    confirmoverridesoa(params) {
        return api.post(`masterdata/confirmOverrideSOA`, params);
    },
    usercosting() {
        return api.get(`masterdata/userCosting`);
    },
    getCustomerCardDetails(params) {
        return api.get("masterdata/getCustomerCardDetails", {params});
    },
    showvendors(id) {
        return api.get(`masterdata/vendors/${id}`);
    },
    getAddressByVendor(params) {
        return api.get(`masterdata/getAddressByVendor`,{params});
    },
    getvehicletypes(params) {
        return api.get("masterdata/get-all-vehicle-types");
    },
    vehicleslisttype(params) {
        return api.get("/masterdata/vehicles-type-list", { params });
    },
    addvehicletype(params) {
        return api.post("/masterdata/add-vehicles-type", params);
    },
    updatevehiclestype(params) {
        return api.put("masterdata/update-vehicles-type", params);
    },
    deletevehiclestype(params) {
        return api.delete(`masterdata/delete-vehicles-type/${params}`);
    },
    currentUser(){
        return api.get('masterdata/current-user');
    },
    customer_code() {
        return api.get("masterdata/customercodes-list");
    },
    addcustomercodes(params) {
        return api.post("masterdata/addcustomercode", params);
    },
    updatecustomercodes(params) {
        return api.put("masterdata/updatecustomercode", params);
    },
    deletecustomercodes(params) {
        return api.delete(`masterdata/deletecustomercode/${params}`);
    },
    getcustomercodeslist(params){
        return api.get(`masterdata/getCustomerCodesList`);
    },
    getCustomerFiles(id){
        return api.get(`masterdata/get-customer-files/${id}`);
    },
    getVendorFiles(params){
        return api.get(`masterdata/get-vendor-files/${params.id}`,{params});
    },
    deleteVendorFiles(params){
        return api.get(`masterdata/delete-vendor-files/${params.id}`,{params});
    },
    deleteCustomerFile(params){
        return api.post(`masterdata/delete-customer-file/`,params);
    },
    uploadCustomerFile(params) {
        return api.post("masterdata/upload-customer-file", params.params,params.config);
    },
    showCustomerCollectibles(params) {
        return api.post("masterdata/show-customer-collectibles", params);
    },
    getListTemplate(params){
        return api.get("dropdown/list-template",{params});
    },
    getTemplate(params){
        return api.get("dropdown/list-template", {params});
    },
    addcustomertemprecord(params) {
        return api.put("masterdata/addCustomerTempRecord", params);
    },
    addcustomertempdiscount(params) {
        return api.post("masterdata/addCustomerTempDiscount", params);
    },
    addcustomertempshippingaddress(params) {
        return api.post("masterdata/addCustomerTempShippingAddress", params);
    },
    addcustomertempbillingaddress(params) {
        return api.post("masterdata/addCustomerTempBillingAddress", params);
    },
    uploadcustomertempfile(params) {
        return api.post("masterdata/uploadCustomerTempFile", params.params,params.config);
    },
    customertemp(params) {
        return api.get("masterdata/customersTempList", { params: params });
    },
    approvecustomertemp(params) {
        return api.post("masterdata/approveCustomerTemp",params );
    },
    getCustomerTempFiles(id){
        return api.get(`masterdata/get-customer-temp-files/${id}`);
    },
    getCustomerChanges(params){
        return api.get(`masterdata/getCustomerChanges`, {params});
    },
    CreateStoreBranches(params) {
        return api.post('/masterdata/company-storebranches', params)
    },
    getVendorCOADetails(params) {
        return api.get(`masterdata/getVendorCOADetails`,{params});
    },
    departmentlistforaccounting() {
        return api.get("dropdown/department-list-for-accounting");
    },
    getclusters(params) {
        return api.get(`masterdata/cluster`, { params: params });
    },
    showcluster(params) {
        return api.get(`masterdata/cluster/${ params.id }`, { params });
    },
    addcluster(params) {
        return api.post(`masterdata/cluster`, params);
    },
    updatecluster(params) {
        return api.put(`masterdata/cluster/${ params.id }`, params);
    },
    deletecluster(params) {
        return api.delete(`masterdata/cluster/${ params.id }`, params);
    },
    getsubclusters(params) {
        return api.get(`masterdata/sub-cluster`, { params: params });
    },
    addsubcluster(params) {
        return api.post(`masterdata/sub-cluster`, params);
    },
    updatesubcluster(params) {
        return api.put(`masterdata/sub-cluster/${ params.id }`, params);
    },
    deletesubcluster(params) {
        return api.delete(`masterdata/sub-cluster/${ params.id }`, params);
    },
    addAddress(params) {
        return api.post(`masterdata/addAddress`, params);
    },
    reportsGet(params){
        return api.get(`masterdata/${params.url}`,{params})
    },
    reportsModulePost(params){
        return api.post(`masterdata/${params.url}`,params)
    },
    submitMassUpdate(params) {
        return api.post(`masterdata/submitMassUpdate`, params);
    },
    systemGet(params){
        return api.get(`/masterdata/${params.url}`,{params})
    },
    getVehicleORCR(params){
        return api.get(`/masterdata/vehicle-orcr/`+params.id,{params})
    },
    systemDetailGet(params){
        return api.get(`/systems/${params.url}`,{params})
    },
    systemItemDropdowns(params){
        return api.get(`/system-link/${params.url}`,{params})
    },
    vehiclePolicyGet(params){
        return api.get(`/masterdata/${params.url}`,{params})
    },
    vehiclePolicyPost(params){
        return api.post(`/masterdata/${params.url}`,params)
    },
    vehicleGet(params){
        return api.get(`/masterdata/${params.url}`,{params})
    },
    vehiclePost(params){
        return api.get(`/masterdata/${params.url}`,{params})
    },
    confirmOverrideAccess(params) {
        return api.post(`masterdata/confirmOverrideAccess`, params);
    },
};
