import repository from '@/api/modules/MasterData'

export default {
    state: {
        data: [],
        system_detail: [],
        system_item_dropdowns: [],
    },
    getters: {
        GET_SYSTEM:(state)=>state.data,
        GET_SYSTEM_DETAIL:(state)=>state.system_detail,
        GET_SYSTEM_ITEM:(state)=>state.system_item_dropdowns,
    },
    actions: {
        systemGet({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.systemGet(payload).then((response)=>{
                    commit('SYSTEM_THEME', response.data)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        systemDetailGet({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.systemDetailGet(payload).then((response)=>{
                    commit('SYSTEM_DETAIL', response.data)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
        systemItemDropdowns({ commit }, payload){
            return new Promise((resolve,reject)=>{
				repository.systemItemDropdowns(payload).then((response)=>{
                    commit('SYSTEM_ITEM', response.data)
                    resolve(response.data)
				}).catch((error)=>{
					reject(error)
				});
			})
        },
    },
    mutations: {
        SYSTEM_THEME:(state,data)=>{
			state.data = data
		},
        SYSTEM_DETAIL:(state,data)=>{
			state.system_detail = data
		},
        SYSTEM_ITEM:(state,data)=>{
			state.system_item_dropdowns = data
		},
    },
};
