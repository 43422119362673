import store from "@/store";

export class shared {
    userLogged(next){
        let urlParams = new URLSearchParams(window.location.search);
            const username = urlParams.get('username');
            const password = urlParams.get('password');
            const token = localStorage.getItem('token')
            let payload = {
                username: username,
                password: atob(password.split('%2B').join('+').split('%2F').join('/').split('%3D').join('='))
            }
            localStorage.setItem('userLogged',JSON.stringify(payload))
            if (token) {
                localStorage.setItem("token", token);
                localStorage.setItem("token2", token);
                window.location.href = window.location.origin + window.location.pathname + '#/home'
                store.dispatch("renderSideNav");
            } else {
                store.dispatch("login", payload).then((success) => {
                localStorage.setItem("token", success.access_token);
                store.dispatch("renderSideNav");
                return next({ name: "home" });
                })
            }
    }
}
