import Vue from "vue";
import VueRouter from "vue-router";
import Credentials from "./Credentials/index";
import HomeView from "@/views/main/HomeView.vue";
import ItStockAdjustmentRouter from "./modules/IT/index";
import ContainersRouter from "./modules/Operations/Containers/index";
import MasterDataRouter from "./modules/MasterData/index";
import SalesItemRouter from "@/router/modules/Sales/Items/index";
import Reports from "./modules/Reports/index";
import SalesReports from "./modules/Reports/SalesReports/index";
import WarehouseInventoryRouter from "@/router/modules/Operations/Inventory/index";
import DispatchesRouter from "@/router/modules/Logistics/Dispatches/index";
import ProcurementPO from "@/router/modules/Procurement/PurchaseOrder/index";
import AcctItemCosting from "@/router/modules/Accounting/ItemCosting/index";
import StockRequest from "@/router/modules/Operations/StockRequest/index";
import StockTransferNew from "@/router/modules/Operations/StockTransferNew/index";
import AcctStatementOfAccount from "@/router/modules/Accounting/StatementOfAccount/index";
import AcctCreditMemo from "@/router/modules/Accounting/CreditMemo/index";
import AcctCreditMemoChina from "@/router/modules/Accounting/CreditMemoChina/index";
import AcctDebitMemo from "@/router/modules/Accounting/DebitMemo/index";
import AcctPayments from "@/router/modules/Accounting/Payments/index";
import AcctPaymentTransmittal from "@/router/modules/Accounting/PaymentTransmittal/index";
import AcctUnconfirmedPl from "@/router/modules/Accounting/UnconfirmedPackingList/index";
import ServiceDashboard from "@/router/modules/Services/index";
import ServiceInquiry from "@/router/modules/Services/Inquiry/index";
import ServiceReceiving from "@/router/modules/Services/Receiving/index";
import ServiceProcessing from "@/router/modules/Services/Processing/index";
import ServiceParts from "@/router/modules/Services/Parts/index";
import ServiceDelivery from "@/router/modules/Services/Delivery/index";
import ServiceTechManager from "@/router/modules/Services/TechManager/index";
import ServiceTechnician from "@/router/modules/Services/Technician/index";
import ServiceItems from "@/router/modules/Services/Items/index";
import ServiceSettings from "@/router/modules/Services/Settings/index";
import ServiceCallCenter from "@/router/modules/Services/CallCenter/index";
import ServiceLocation from "@/router/modules/Services/Locations/index";
import ServiceStockTransfer from "@/router/modules/Services/StockTransfer/index";
import ServiceStockRequest from "@/router/modules/Services/StockRequest/index";
import ServiceBaklas from "@/router/modules/Services/Baklas/index";
import ServiceSalesOrder from "@/router/modules/Services/SalesOrder/index";
import ServiceReports from "@/router/modules/Reports/service";
import ServiceReportsButtons from "@/router/modules/Reports/ServiceReports/index";
import ServiceSalesQuotation from "@/router/modules/Services/SalesQuotation/index";
import CycleCount from "@/router/modules/Operations/CycleCount/index";
import ItemJournal from "@/router/modules/Operations/ItemJournal/index";
import InventoryReports from "./modules/Reports/InventoryReports/index";
import StockVariance from "./modules/Operations/StockVariance/index";
import AccountingReports from "./modules/Reports/AccountingReports/index";
import Inbound from "./modules/Operations/Inbound/index";
import Outbound from "./modules/Operations/Outbound/index";
import SystemSettings from "./Main/SystemSettings/SystemSettings";
import noaccess from "@/views/main/no-access.vue";
import repository from "@/api/credentials";
import salesquotationnew from "@/router/modules/Sales/SalesQuotation/index";
import SalesOrderGroupComponent from "@/router/modules/Sales/SalesOrdersNew/index";
import newwpf from "@/router/modules/Operations/WPF/index";
import newCF from "@/router/modules/Operations/CF/index";
import PackingListRouter from "@/router/modules/Sales/PackingList/index";
import ServicePackingListRouter from "@/router/modules/Services/PackingList/index";
import StockCardComponent from "@/router/modules/Operations/StockCards/index";
import PromosRouter from "@/router/modules/Sales/Promos/index";
import LogisticsBasket from "@/router/modules/Logistics/Basket/index";
import WarehouseCalendar from "@/router/modules/Operations/WarehouseCalendar/index";
import Maps from "@/router/modules/CustomerMaps/customer_maps";
import SalesDashboard from "@/router/modules/Sales/Dashboard/index";
import ChangePassword from "@/router/Utils/index";
import WarehouseUser from "@/router/modules/Operations/WarehouseUser/index";
import AccountingUser from "@/router/modules/Accounting/AccountingUser/index";
import AccountingBanks from "@/router/modules/Accounting/Banks/index";
import JournalVoucher from "@/router/modules/Accounting/JournalVoucher/index";
import ChequeVoucher from "@/router/modules/Accounting/ChequeVoucher/index";
import TargetSales from "@/router/modules/TargetSales/index";
import Admin from "@/router/modules/Admin/index";
import dailyManagementSystem from "@/router/modules/Sales/Dms/index"
import PackingListHistory from "@/router/modules/Accounting/PackingListHistory/index";
import AccountsPayable from "@/router/modules/Accounting/AccountsPayable/index";
import HR from "@/router/modules/HR/index"
import HRSettings from "@/router/modules/HR/Settings/index"
import HRLeaveRequest from "@/router/modules/HR/Leaves/index"
import HRLists from "@/router/Main/Lists/index"
import HRPlantilla from "@/router/modules/HR/Plantilla/index"
import HROvertime from "@/router/modules/HR/Overtime/index"
import HRRecruitment from "@/router/modules/HR/Recruitment/index"
import WebMail from "@/router/modules/WebMail/index"

import store from '@/store';
import AdminReport from "@/router/modules/Reports/AdminReports/index"
import Queue from "@/router/modules/Operations/Queue";
import OnlineTracking from "./modules/Marketing/index";
import SpareParts from "@/router/modules/Services/SpareParts/index"
import SampleForm from "@/router/modules/Operations/SampleForm/index";
import ContainerDiscrepancy from "@/router/modules/Operations/ContainerDiscrepancy/index"
import Timekeeping from "@/router/modules/HR/Timekeeping/index";
// import ChartOfAccount from "@/router/modules/Accounting/ChartOfAccounts/index";
import ItemsAutoFocComponent from "@/router/modules/Services/ItemFoc/itemFoc"
import PartsTransfer from "@/router/modules/Services/PartsTransfer/index"

import TesterCheckLists from "@/router/modules/Services/TesterCheckLists/index"
import ServiceTester from "@/router/modules/Services/ServiceTester/index"
import AutoFOC from "@/router/modules/Services/AutoFOC/index"
import Defective from "@/router/modules/Services/Defective/index"
import ChartOfAccount from "@/router/modules/Accounting/ChartOfAccounts/index";
import ServiceLogisticsBasket from "@/router/modules/Services/Logistics/Basket/index";
import StocksInquiry from "@/router/modules/Sales/StocksInquiry/index"
// import Payroll from "@/router/modules/HR/Payroll/index"
import HRUser from "@/router/modules/HR/HrUser/index"
import SparePartsStagingArea from "@/router/modules/Services/SparePartsStagingArea/index"
import Disposal from "@/router/modules/Services/Disposal/index";

import StoreBranchesAccess from "@/router/modules/Accounting/StoreBranchesAccess"
import PayBills from "@/router/modules/Accounting/PayBills/index"
import TransactionJournal from "@/router/modules/Accounting/TransactionJournal/index"
import ServiceRequest from "@/router/modules/Accounting/ServicePaymentRequest/index"
import admintransactions from "@/router/modules/Admin/AdminTranasaction";
import StoreCashIn from "@/router/modules/Accounting/StoreCashIn/index"

import StoreFlow from "@/router/modules/Stores_/StoreFlow/index"
import LogisticsReport from "@/router/modules/Reports/LogisticsReports/index"
import Financials from "@/router/modules/Accounting/Financials/index"
import DebitMemoHistory from "@/router/modules/Accounting/DebitMemoHistory/index";
import AccountingDepartment from "@/router/modules/Accounting/AccountingDepartment/index"
import CashAdvance from "@/router/modules/Accounting/CashAdvance/index"
import EmployeeMemo from "@/router/modules/HR/EmployeeMemo/index"
import CreditBill from '@/router/modules/Accounting/CreditBill/index'

import {shared} from '@/utils/index'

Vue.use(VueRouter);
const routes = [
    ...Credentials,
    {
        path: "/home",
        name: "home",
        title: "Home",
        component: HomeView,
        beforeEnter: (to, from, next) => {
            repository
                .authentication()
                .then(() => {
                    store.dispatch("credentials");
                    store.dispatch('GeneralSettings');
                    store.dispatch("renderSideNav");
                    if (window.location.search) {
                        const url = new URL(window.location.href)
                        const params = new URLSearchParams(url.search.slice(1))
                        params.delete('username')
                        params.delete('password')
                        window.history.replaceState(
                            {},
                            '',
                            `${window.location.pathname}${window.location.hash}`,
                        )
                    }
                    next();
                })
                .catch(() => {
                    localStorage.setItem("last_visit",to.fullPath);
                    localStorage.removeItem("token");
                    if(localStorage.getItem('userLogged') || localStorage.getItem('token2')){
                        let payload = JSON.parse(localStorage.getItem('userLogged'));
                          store.dispatch("login", payload).then((success) => {
                            localStorage.setItem("token", success.access_token);
                            store.dispatch("renderSideNav");
                            next({ name: "home" })
                            if(localStorage.getItem("token")){
                                localStorage.removeItem('userLogged')
                                localStorage.removeItem('token2')
                            }
                        })
                    }
                    else if(window.location.search){
                        const utils = new shared()
                        utils.userLogged(next)
                    }
                    else{
                        return next({ name: "login" });
                    } 
                });
        },
        children: [
            ...MasterDataRouter,
            ...ItStockAdjustmentRouter,
            ...ContainersRouter,
            ...SalesItemRouter,
            ...Reports,
            ...SalesReports,
            ...WarehouseInventoryRouter,
            ...DispatchesRouter,
            ...ProcurementPO,
            ...AcctItemCosting,
            ...StockRequest,
            ...StockTransferNew,
            ...AcctStatementOfAccount,
            ...AcctCreditMemo,
            ...AcctCreditMemoChina,
            ...AcctDebitMemo,
            ...AcctPayments,
            ...AcctUnconfirmedPl,
            ...ServiceDashboard,
            ...ServiceInquiry,
            ...ServiceReceiving,
            ...ServiceProcessing,
            ...ServiceParts,
            ...ServiceDelivery,
            ...ServiceTechManager,
            ...ServiceTechnician,
            ...ServiceItems,
            ...ServiceSettings,
            ...ServiceCallCenter,
            ...ServiceLocation,
            ...ServiceStockTransfer,
            ...ServiceStockRequest,
            ...ServiceBaklas,
            ...ServiceReports,
            ...ServiceReportsButtons,
            ...ServiceSalesQuotation,
            ...ServiceSalesOrder,
            ...CycleCount,
            ...ItemJournal,
            ...InventoryReports,
            ...StockVariance,
            ...AccountingReports,
            ...Inbound,
            ...Outbound,
            ...SystemSettings,
            ...salesquotationnew,
            ...SalesOrderGroupComponent,
            ...newwpf,
            ...newCF,
            ...PackingListRouter,
            ...ServicePackingListRouter,
            ...StockCardComponent,
            ...PromosRouter,
            ...LogisticsBasket,
            ...WarehouseCalendar,
            ...Maps,
            ...SalesDashboard,
            ...ChangePassword,
            ...WarehouseUser,
            ...AccountingUser,
            ...AccountingBanks,
            ...TargetSales,
            ...Admin,
            ...dailyManagementSystem,
            ...SpareParts,
            ...HR,
            ...HRSettings,
            ...HRLeaveRequest,
            ...HRLists,
            ...HRPlantilla,
            ...HROvertime,
            ...HRRecruitment,
            ...OnlineTracking,
            ...WebMail,
            ...AccountsPayable,
            ...AdminReport,
            ...Queue,
            ...PackingListHistory,
            ...SampleForm,
            ...ContainerDiscrepancy,
            ...Timekeeping,
            // ...ChartOfAccount,
            ...TesterCheckLists,
            ...ServiceTester,
            ...ItemsAutoFocComponent,
            ...AutoFOC,
            ...PartsTransfer,
            ...Defective,
            ...ChartOfAccount,
            ...ServiceLogisticsBasket,
            ...StocksInquiry,
            // ...Payroll,
            ...HRUser,
            ...JournalVoucher,
            ...ChequeVoucher,
            ...SparePartsStagingArea,
            ...Disposal,
            ...StoreBranchesAccess,
            ...PayBills,
            ...TransactionJournal,
            ...ServiceRequest,
            ...admintransactions,
            ...StoreFlow,
            ...StoreCashIn,
            ...LogisticsReport,
            ...Financials,
            ...DebitMemoHistory,
            ...AccountingDepartment,
            ...AcctPaymentTransmittal,
            ...CashAdvance,
            ...EmployeeMemo,
            ...CreditBill,
            {
                path: "/no-access",
                component: noaccess,
                meta: {
                    requiresAuth: true,
                },
            },
        ],
        meta: {
            requiresAuth: true,
            title: "Home",
        },
    },
];
const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !localStorage.getItem("token")) {
        next();
    } else if (!to.meta.requiresAuth && localStorage.getItem("token")) {
        next("/home");
    } else {
        if (typeof to.meta.title !== "undefined") {
            document.title = `${process.env.VUE_APP_TITLE} - ${to.meta.title}`;
        } else {
            document.title = `${process.env.VUE_APP_TITLE}`;
        }
        next();
    }
});

export default router;
