	<template>
		<v-container v-model="valid" lazy-validation class="dialog_box" style="width:80%;" fluid @click="closeCelebrantDialog()">
            <v-row class="ma-0">
                <v-col cols="12" class="text-center">
                    <v-col class="mx-2 text-white">
                        <h1>Please Give A Warm Greetings for Today's Birthday Celebrants!</h1>
                    </v-col>
                </v-col>
            </v-row>
            <v-row ref="scrollRow" style="height:40vh; overflow:auto;" class="d-flex justify-content-center">
                <v-col v-for="(data, i) in celebrants" :key="i" cols="12" sm="6" md="4" lg="3" class="d-flex justify-center align-center">
                    <v-card color="#f69f1a" class="ma-2" height="100%" max-width="100%">
                        <v-container>
                            <v-row>
                                <v-col cols="12" class="d-flex align-center justify-center">
                                    <v-avatar size="200" class="ma-2">
                                        <v-img :src="data.employee_image_base64" contain />
                                    </v-avatar>
                                </v-col>
                                <!-- <v-col class="text-center text-white">
                                    <h5>{{ formatDate(data.birth_date) }}</h5>
                                </v-col> -->
                                <v-col cols="12" class="text-center text-white">
                                    <h4>{{ data.full_name.toUpperCase() }}</h4>
                                </v-col>
                                <v-col cols="12" class="text-center text-white">
                                    <h5>{{ data.job_title_name +  ' ('+data.department_name_long+')' }}
                                    </h5>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

	</template>
<script>
// import confetti from 'canvas-confetti';
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            valid: true,
            celebrants :[]
        }
    },
    computed: {
        ...mapGetters(['ACTION',
            'CELEBRANTS_DIALOGS',
            'GET_EMPLOYEE_BIRTHDAY_CELEBRANTS'
        ]),
    },
    mounted(){
        this.$store.dispatch('getSelectionDepartmentRole');
        // this.confettiAnimation()
        this.getCelebrantsToday()
    },
    methods: {
        startScrolling() {
            const scrollRow = this.$refs.scrollRow;
            if (scrollRow) {
                const scrollHeight = scrollRow.scrollHeight;
                let scrollTop = 0;
                const scrollSpeed = 20; // Adjust scroll speed as needed

                setInterval(() => {
                if (scrollTop < scrollHeight) {
                    scrollTop += scrollSpeed;
                    scrollRow.scrollTop = scrollTop;
                }
                }, 100); // Adjust scroll interval as needed
            }
        },
        formatDate(dateString) {
            const date = this.$dayjs(dateString);
            const month = date.format('MMMM'); // Long month name
            const day = date.format('D');      // Day of the month
            const year = date.format('YYYY');  // Year
            return `${month} ${day}, ${year}`;
        },
        getCelebrantsToday(){
            const currentDate = this.$dayjs().format('YYYY-MM-DD');

            this.celebrants = Object.values(this.GET_EMPLOYEE_BIRTHDAY_CELEBRANTS).map(obj => obj);
            let filtered = this.celebrants.filter(e => {
            const birthDate = this.$dayjs(e.birth_date, 'YYYY-MM-DD');
            e.employee_image_base64 = e.employee_image_base64 ? e.employee_image_base64 : process.env.VUE_APP_API_HOST + "/images/blank-picture.png";
                return birthDate.format('MM-DD') === this.$dayjs().format('MM-DD');
            });
            this.celebrants = filtered
            $('.dialog_box').parent().attr('style','box-shadow:none')
            // $('.test').parent().parent().parent().find('.v-overlay__scrim').attr('style','opacity:0;')
            // console.log($('.test').parent().parent().parent().find('.v-overlay__scrim'))
            // this.startScrolling()
        },
        closeCelebrantDialog(){
            this.$store.commit('CELEBRANTS_DIALOG_MUTATE',false)
        },
        // confettiAnimation() {
        //     const duration = 1000 * 3; // duration of animation in milliseconds
        //     const animationEnd = Date.now() + duration;

        //     const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        //     function randomInRange(min, max) {
        //         return Math.random() * (max - min) + min;
        //     }

        //     function animate() {
        //         const timeLeft = animationEnd - Date.now();

        //         if (timeLeft <= 0) {
        //         return;
        //         }

        //         const particleCount = 40 * (timeLeft / duration);

        //         confetti({
        //         ...defaults,
        //         particleCount,
        //         origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        //         });
        //         confetti({
        //         ...defaults,
        //         particleCount,
        //         origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        //         });

        //         requestAnimationFrame(animate);
        //     }
        //     animate();
        // },
	},
	watch: {
		CELEBRANTS_DIALOGS:{
			handler(val){
				if(val){
                    this.getCelebrantsToday()
                }
			}
		},
	},
};
</script>
<style scoped>

h4 ,h1 , h5 {
    font-family: 'Kaushan Script', cursive;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 0, 0);
  display: none;


}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(246, 159, 26);
  border-radius: 20px;
  display: none;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(246, 159, 26);
  display: none;
}
</style>
