<script>
    import currencyToWords from 'currency-to-words';
    import Swal from 'sweetalert2';

    export default {
        data() {
            return {
                windowTop: 0
            }
        },
        mounted(){
            window.addEventListener("scroll", this.onScroll, true);

        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll, true);
        },
        methods: {
            toCurrency(currency, amount, decimalPrecision ) {
                const options = {
                    minimumFractionDigits: decimalPrecision,
                    maximumFractionDigits: decimalPrecision,
                };

                if (currency) {
                    options.style = 'currency';
                    options.currency = currency.toUpperCase();
                }

                return parseFloat(amount).toLocaleString('en-US', options);
            },
            decimalOnly ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105) && keyCode != 8 && keyCode != 9 && keyCode !== 46 && keyCode !== 190) { // 46 is dot
                    $event.preventDefault();
                }
            },
            numberOnly ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                if ((keyCode < 48 || keyCode > 57) && keyCode != 8 && keyCode != 9) {
                    $event.preventDefault();
                }
            },
            thousandSeprator(amount) {
                if (!isNaN(amount)) {
                    return amount.toString().trim().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                }

                return amount;
            },
            round(value, decimals) {
                return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            },
            getTwoDecimals (amount, places = 2) {
                var formatted_amount = ''
                if(isNaN(amount)){
                    if(amount != null && amount != ''){
                        formatted_amount = parseFloat(amount.replace(/,/g, ''))
                        formatted_amount = Math.trunc(formatted_amount * Math.pow(10, places)) / Math.pow(10, places)
                    }
                }else{
                    formatted_amount = Math.trunc(amount * Math.pow(10, places)) / Math.pow(10, places)
                }

                if (formatted_amount == '') {
                    return 0.00
                }
                return this.thousandSeprator(formatted_amount.toFixed(places))
            },
            getDatesFromRange(startDate, endDate, return_type = 'array') {
                var dates = []
                var currDate = this.$dayjs(startDate).startOf('day')
                var lastDate = this.$dayjs(endDate).startOf('day')

                while(currDate.diff(lastDate) <= 0) {
                    switch (return_type) {
                        case 'array':
                            dates.push(currDate.clone().format('YYYY-MM-DD'))
                            break
                        case 'object':
                            dates.push(Object.assign({},{
                                value: currDate.clone().format('YYYY-MM-DD'),
                                text: currDate.clone().format('YYYY-MM-DD (dddd)')
                            }))
                            break
                        default:
                    }
                    currDate = currDate.add(1, 'day')
                }
                return dates
            },
            upperCaseFirstLetter(str, allCaps = false){
                const arr = str.split(" ");
                for (var i = 0; i < arr.length; i++) {
                    if (allCaps) {
                        arr[i] = arr[i].toUpperCase();
                    } else {
                        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                    }
                }
                str = arr.join(" ");
                return str
            },
            ConverToExcelJsColumns(data){
              if(!data.length > 0){
                  return []
              }
                let headers = data[0]
                return Object.keys(headers).map(e => {
                        return { header: this.upperCaseFirstLetter(e.replaceAll('_',' ')),key: e }
                    })
            },
            leadingZeros(number = 0,count = 4){

                let digitCount = number.toString().length
                let stringedNumber = number.toString()

                if(digitCount > count){
                    return 0
                }

                while(digitCount != count){
                    stringedNumber = '0' + stringedNumber
                    digitCount++
                }

                return stringedNumber;
            },

            arraySlice(array,count = 10){
                return array.reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index/count)

                    if(!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [] // start a new chunk
                    }

                    resultArray[chunkIndex].push(item)

                    return resultArray
                    }, [])
            },

            formatCurrencyPHP(amount) {
                return parseFloat(amount).toLocaleString('en-PH', {style: 'currency', currency: 'PHP'});
            },

            formatCurrencyPHPwithoutRoundingUp(value) {
                if(value){
                    let array_of_numbers = value.toString().split(".");
                    let whole_number = array_of_numbers[0]
                    let decimal = '000'
                    if(array_of_numbers[1]){
                        decimal = array_of_numbers[1].slice(0,3)
                    }

                    return parseFloat(whole_number + "." + decimal)
                }
            },

            sliceIntoChunks(arr, chunkSize) {
                const res = [];
                for (let i = 0; i < arr.length; i += chunkSize) {
                    const chunk = arr.slice(i, i + chunkSize);
                    res.push(chunk);
                }
                return res;
            },
            errorObjects(type){
                switch(type){
                    case 1:
                        return {
                            title:'Warehouse Not Found',
                            color:'#8271FA'
                        }
                    case 2:
                        return {
                            title:'Item Not Found',
                            color:'#71ADFA'
                        }
                    case 3:
                        return {
                            title:'Can\'t request at the sames warehouse',
                            color:'#FA7192'
                        }
                }
            },

            formatDate(value) {
                if (!value) {
                    return ''
                }
                return value.indexOf(":") != -1
                    ? new Date(Date.parse(value.replace(" ", "T") + ".000Z"))
                    : new Date(value);
            },
            formatDateandTime(value) {
                if (!value) {
                    return ''
                }
                return this.$dayjs(String(value)).format('MM/DD/YYYY hh:mm A')
            },
            formatTime(time, format = 'hh:mm') {
                let parsedTime = this.$dayjs(time);
                if (!parsedTime.isValid()) return '';
                return parsedTime.format(format);
            },
            getDistinctObjects(array, property) {
            return array.filter((object, index) => {
                const firstIndex = array.findIndex(
                        (item) => item[property] === object[property]
                    );
                    return index === firstIndex;
                });
            },
            formattedDate(date) {
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            },
            formattedTime(date) {
                const hours = date.getHours();
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const period = hours >= 12 ? 'PM' : 'AM';
                const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
                return `${formattedHours}:${minutes} ${period}`;
            },
            dateWithFormat(value ='',format ='') {
                if (!value || !format) {
                    return ''
                }
                return this.$dayjs(String(value)).format(format)
            },

            // devide array base on desired attribute
            // return data based on 3rd parameter (array or objectonly)
            divideArrayByAttribute(array = [],attributeName = '', returnType = 'array'){
                if(array.length == 0 || !attributeName){
                    console.log('error in divideArrayByAttribute function')
                    return array
                }

                let attributes = [...new Set(array.map(e=> e[attributeName]))]
                let cleanArray = []
                attributes.forEach(e=>{
                    let arr = []
                    array.forEach(el =>{
                        if(el[attributeName] == e){
                            arr.push(el)
                        }
                    })

                    returnType == 'array' ? cleanArray.push(arr) : arr = Object.assign(cleanArray,{
                        [e]:arr
                    })
                })
                return cleanArray;
            },

            percent(number,total){
                if(!number || !total){
                    return false
                }
                return `${this.getTwoDecimals((number / total) * 100)}%`
            },

            // sum all the value inside the array base on the attribute given
            sumArraybyAttribute(array = [],attributeName){

                if(array.length == 0 || !attributeName){
                    console.log('error in sumArraybyAttribute function')
                    return 0
                }

                let sum = 0
                array.forEach(e=>{
                    if(typeof e[attributeName] === 'string'){
                        e[attributeName] = e[attributeName].replace(',','')
                    }
                    sum += parseFloat(e[attributeName])
                })
                // console.log(sum)
                if(isNaN(sum) ){
                    return 0
                }
                return sum
            },

            combineObjects(array) {
                var combinedObject = {};

                for (var i = 0; i < array.length; i++) {
                    var obj = array[i];
                    for (var key in obj) {
                        if (obj.hasOwnProperty(key) && obj[key] !== null) {
                        combinedObject[key] = obj[key];
                        }
                    }
                }

                return combinedObject;
            },
            checkImagePath(image_path) {
                return image_path == null
                    ? process.env.VUE_APP_API_HOST + "/images/items/no-image.png"
                    : process.env.VUE_APP_API_HOST + "/img/items?image_path=" + image_path + "&image_path_basis=1"

            },

            // checks if the object already exist in array, if does, update the attribute given base on the operator
            // if not, simple push the object to the array
            addOrUpdateToArray(array = [],object = {},attribute = '',opperator = '+',attributeToCompare = 'id'){
                if(!attribute || array.lenght == 0 || attribute == ''){
                    console.log('error in addOrUpdateArray function')
                    return
                }

                let exist_in_array = isObjectInArray(array,object,attributeToCompare)

                if(exist_in_array){

                    array.forEach(e=>{
                        if(object[attributeToCompare] == e[attributeToCompare]){
                            e[attribute] = performOperation(e[attribute],object[attribute],opperator)
                        }
                    })
                }else{
                    array.push(object)
                }

                function performOperation(a, b, operator) {
                    switch (operator) {
                        case "+":
                        return a + b;
                        case "-":
                        return a - b;
                        case "*":
                        return a * b;
                        case "/":
                        return a / b;
                        default:
                        throw new Error("Invalid operator");
                    }
                }

                function isObjectInArray(array, obj, attribute) {
                    let in_array = false
                    array.forEach(e=>{
                        if(e[attribute] == obj[attribute]){
                            in_array = true
                        }
                    })

                    return in_array;
                }

                return array
            },

            toggleDialog(commit,state){
                this.$store.commit(commit,!state)
            },

            async stockChecker(items,warehouse){
                if(items.lenght == 0 || !!warehouse){
                    return nulld
                }

                let data = null

                await this.$store.dispatch('servicePost',{
                    url:'itemStocks',
                    items:items,
                    warehouse:warehouse
                }).then(response =>{
                    data = response.data
                })

                return data
            },
            isStringConvertibleToJSON(inputString) {
                try {
                    const jsonObject = JSON.parse(inputString);
                    return typeof jsonObject === 'object';
                } catch (e) {
                    return false; // Parsing the string as JSON failed
                }
            },
            replaceSpecialCharactersForRouting(fileName) {
                let replacements = {
                '#': '%23',
                };

                // Replace specified characters with their designated replacements
                let modifiedFileName = fileName;
                Object.entries(replacements).forEach(([char, replacement]) => {
                    modifiedFileName = modifiedFileName.replace(char, replacement);
                });

                return modifiedFileName;
            },

            divideArray(array,divideBy){
                let clean_array = []
                let temp = []

                array.forEach((e,i)=>{
                    e.index = i
                    temp.push(e)

                    if((i + 1) % divideBy == 0){
                        clean_array.push(temp)
                        temp = []
                    }

                    if(array.length == (i + 1)){
                        clean_array.push(temp)
                        temp = []
                    }
                })

                return clean_array
            },
            findIndexInArrayObjects(array, property ,keyToFind){
                const pos = array.map(e => e[property]).indexOf(keyToFind);
                return pos;
            },
            getYearRange(mutation = null,startYear = 5,endYear = 5){
                let range = []
                let start = this.$dayjs().subtract(startYear, 'year').year()
                let end = this.$dayjs().add(endYear, 'year').year()
                for (let c = start; c <= end; c++) {
                    range.push(c)
                }
                if(mutation){
                    this.$store.commit(mutation, range);
                }
                return range
            },
            async exportFile(filename, sheetname, data, settings, defaultWidth) {
                const workbook = new this.$exceljs.Workbook();
                const worksheet = workbook.addWorksheet(sheetname);

                // Formatting headers before adding them.
                let headers = data[0];
                headers = Object.keys(headers).map((e) => {
                    return {
                        header: this.upperCaseFirstLetter(e.replaceAll("_", " ")),
                        key: e,
                        style:
                            e.toUpperCase().includes("AMOUNT") ||
                                e.toUpperCase().includes("COST") ||
                                e.toUpperCase().includes("TOTAL BOOKED") ||
                                e.toUpperCase().includes("TOTAL BOOKED PENDING AMOUNT") ||
                                e.toUpperCase().includes('DISC PRICE') ||
                                e.toUpperCase().includes("TOTAL INVOICED")
                                ? { numFmt: "#,##0.00" }
                                : "",
                    };
                });
                worksheet.columns = headers;

                // // Formatting and validation before insert of data.
                for (let index in data) {
                    const obj = data[index];
                    for (const key of Object.keys(obj)) {
                        const value = obj[key];
                        if (value === "null") {
                            obj[key] = null;
                        } else if (value === "true" || value === "false") {
                            obj[key] = value === "true";
                        } else if (value === "") {
                            obj[key] = "";
                        } else if (value === " ") {
                            obj[key] = "";
                        } else if (value === null) {
                            obj[key] = "";
                        } else if (!isNaN(value)) {
                            obj[key] = Number(value);
                        } else if (this.$dayjs(value, 'YYYY-MM-DDTHH:MN:SS.MSSZ').isValid()) {
                            obj[key] =
                                value.indexOf(":") != -1
                                    ? new Date(Date.parse(value.replace(" ", "T") + ".000Z"))
                                    : new Date(value);
                        }
                    }
                    worksheet.addRow(obj);
                }

                // // Set the column styles.
                headers.forEach((header, index) => {
                    if (settings.length <= 0){
                        worksheet.getColumn(this.numberToColumnLetter(index + 1)).width = defaultWidth
                    }
                    settings.forEach(setting => {
                        if (header.key === setting.key) {
                            worksheet.getColumn(this.numberToColumnLetter(index + 1)).width = setting.width ? setting.width : {};
                            worksheet.getColumn(this.numberToColumnLetter(index + 1)).alignment = setting.rowAlignment ? setting.rowAlignment : {};
                        }  else {
                            worksheet.getColumn(this.numberToColumnLetter(index + 1)).width = defaultWidth
                        }
                    });
                });
                worksheet.getRow('1').alignment = { horizontal: 'center' };

                await workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                    });
                    this.$filesaver.saveAs(
                        blob,
                        `${filename}.xlsx`
                    );
                });
            },
            upperCaseFirstLetter(str) {
                const arr = str.split(" ");
                for (var i = 0; i < arr.length; i++) {
                    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                }
                str = arr.join(" ");
                return str;
            },
            numberToColumnLetter(number) {
                let columnLetter = '';
                while (number > 0) {
                    const remainder = (number - 1) % 26;
                    columnLetter = String.fromCharCode(65 + remainder) + columnLetter;
                    number = Math.floor((number - 1) / 26);
                }
                // console.log(columnLetter);
                return columnLetter;
            },
            newLine(event, last, array, currentRowIndex, attributeNameToFocus, addRowFunction, vuetifyMoney, arrowRight, arrowUp, arrowLeft) {
                let didNewLine = false;
                let particularsContainerRows = document.querySelector('.particulars-container');

                this.indexToBeFocused = currentRowIndex + 1;
                this.attributeToBeFocused= attributeNameToFocus;

                if (currentRowIndex < particularsContainerRows.length - 1) return;

                if (event.ctrlKey && event.key === 'ArrowDown') {
                    if (array) return;
                    event.preventDefault();
                    addRowFunction(attributeNameToFocus);
                    didNewLine = true;
                }
                else if (event.ctrlKey && event.key === 'ArrowUp') {
                    last();
                    if (array) return;
                    event.preventDefault();
                }
                 else if (event.key === 'Tab' && last) {
                    if (event.shiftKey) return;
                    event.preventDefault();
                    addRowFunction(attributeNameToFocus);
                    didNewLine = true;
                }
                else if (event.key === 'ArrowRight') {
                    event.preventDefault();
                    if(arrowRight.includes('textFields')){
                        const input = this.$refs[arrowRight][currentRowIndex].$el.querySelector('input');
                        if (input) {
                            input.focus();
                        }
                    } else {
                        this.$refs[arrowRight][currentRowIndex].focus();
                    }
                }
                else if (event.key === 'ArrowLeft') {
                    event.preventDefault();
                    const lastIndex = currentRowIndex - 1;
                    if(arrowLeft.includes('textFields')){
                        const input = this.$refs[arrowLeft][currentRowIndex].$el.querySelector('input');
                        if (input) {
                            input.focus();
                        }
                    } else {
                        this.$refs[arrowLeft][currentRowIndex].focus();
                    }
                }
                else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    event.preventDefault();
                    let lastIndex = '';
                    if(event.key === 'ArrowUp'){
                        lastIndex = currentRowIndex - 1;
                    } else if (event.key === 'ArrowDown') {
                        lastIndex = currentRowIndex + 1;
                    }
                    if(arrowUp.includes('textFields')){
                        const input = this.$refs[arrowUp][lastIndex].$el.querySelector('input');
                        if (input) {
                            input.focus();
                        }
                    } else {
                        this.$refs[arrowUp][lastIndex].focus();
                    }
                }

                // else if (event.key === 'ArrowLeft') {
                //         this.$refs[type][currentRowIndex].focus();
                // }
                else if (event.key === '.') {
                    this.$store.commit('PRECISION', 2)
                }

                if (!didNewLine) return;

                this.$nextTick(() => {
                    let particularsContainerRows = document.querySelector('.particulars-container').getElementsByClassName('particulars-container-row');
                    let targetRow = particularsContainerRows[particularsContainerRows.length - 1];
                    if (vuetifyMoney) return targetRow.querySelector(`#${attributeNameToFocus}`).querySelector('input').focus();
                    targetRow.querySelector(`#${attributeNameToFocus}`).focus();
                })
            },
            async addImageToWorksheet(workbook, worksheet, item, pos){
                let height = 80;
                let width = 60;
                let aspectRatio = null;

                worksheet.getRow(pos + 1).height = height;

                let image = await new Promise((resolve) => {
                    let img = new Image();
                    img.src = item.base64img;
                    img.onload = () => resolve(img);
                });

                aspectRatio = image.naturalWidth / image.naturalHeight;
                let imageHeight = aspectRatio * width;

                let finalImage = workbook.addImage({
                    base64: item.base64img,
                    extension: 'jpg',
                });

                worksheet.addImage(finalImage, {
                    tl: { col: 0.1, row: pos + 0.1 },
                    // br: { col: 1, row: worksheet.rowCount },
                    ext: { width: 140, height: 115 },
                    editAs: 'twoCell',
                });

                if (item.hasOwnProperty('sub_item_model_base64img')) {
                    let subModel = workbook.addImage({
                        base64: item.sub_item_model_base64img,
                        extension: 'jpg',
                    });

                    worksheet.addImage(subModel, {
                        tl: { col: 12.1, row: pos + 0.1},
                        // br: { col: 13, row: worksheet.rowCount },
                        ext: { width: 140, height: 115 },
                        editAs: 'twoCell',
                    });
                }

                // if (item.base64NewItemTag !== null) {
                //     let finalImageNewItem = workbook.addImage({
                //         base64: item.base64NewItemTag,
                //         extension: 'jpg',
                //     });

                //     worksheet.addImage(finalImageNewItem, {
                //         tl: { col: 0, row: pos },
                //         ext: { width: 50, height: 50 },
                //         editAs: 'undefined',
                //     });
                // }

                let row = worksheet.getRow(worksheet.rowCount);
                row.width = width
                if (imageHeight > worksheet.getRow(pos + 1).height) {
                    worksheet.getRow(pos + 1).height = imageHeight;
                }
            },
             distinctArrayOfObjects(array, key) {
                return array.reduce((acc, obj) => {
                    if (!acc.some(item => item[key] === obj[key])) {
                    acc.push(obj);
                    }
                    return acc;
                }, []);
            },
            convertAmountToWordsCurrency(amount) {
                const splittedAmount = parseFloat(amount).toFixed(2).toString().split('.');
                let currencyWords = currencyToWords(parseInt(splittedAmount[0]));
                currencyWords = currencyWords.split(' ').slice(0, -1);
                currencyWords = currencyWords.map(element => {
                    return element.charAt(0).toUpperCase() + element.slice(1);
                });
                currencyWords = currencyWords.join(' ');
                const decimalPart = splittedAmount[1];
                return `${currencyWords} Pesos${
                    decimalPart !== '00'
                    ? ` and ${decimalPart}/100 only`
                    : ' only'
                }`;
            },
            closeDialogByEsc(event, param){
                if(event.key == 'Escape'){
                    param();
                } else {
                    return
                }
            },

            // removeSpecialCharacters(str) {
            //     return str.replace(/[^a-zA-Z0-9 ]/g, '');
            // }
            getStoreBranches(company_id = null){
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('getPaymentVoucherBranchesSelection',{company_id:company_id}).then(response=>{
                        resolve(response)
                    }, error => {
                        reject(error)
                    })
                })
            },
            truncateText(text, length){
                if (text && text.length > length) {
                    return text.substring(0, length) + '...'
                }
                return text
            },
            phoneNumberOnly($event){
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
                let input = $event.target.value;

                if ((keyCode < 48 || keyCode > 57) && keyCode != 8 && keyCode != 9) {
                    $event.preventDefault();
                }

                if (input.length >= 11 && keyCode !== 8 && keyCode !== 9) {
                    $event.preventDefault();
                }
            },
            createEnterBill(transaction_number,parent_id,company_id,store_branch_id,transact_type_id,vendor_id,bill_date,amount_due,items){
                let item_data = []

                items.forEach(e=>{
                    let mappedItem = {
                        transaction_number: transaction_number,
                        transact_id: parent_id,
                        transact_type_id: transact_type_id,
                        total_amount: e.amount,
                        balance: e.amount,
                        pending_amount: e.amount,
                        amount_to_pay: e.amount,
                        transact_item_id: e.id
                    }
                    item_data.push(mappedItem)
                })

                let form = {
                    company_id:company_id,
                    store_branch_id:store_branch_id,
                    transact_type_id:transact_type_id,
                    vendor_id:vendor_id,
                    status:1,
                    bill_date:bill_date,
                    amount_due:amount_due,
                    items:item_data
                }

                this.$store.dispatch('EnterBillPost',{
                    url:'modify-enter-bills',
                    ...form
                }).then(response=>{

                }).catch(err=>{
                    console.log(err)
                    Swal.fire('Error!','','error')
                })
            },
            trimWareHouseItemName(item){
                if (!item.includes('|')) {
                    return "";
                }
                let parts = item.split('|');
                return parts[1].trim();
            },
            upperCaseWord(text){
                return text?.toUpperCase()
            },
            async exportExcel(dataToExport, workbookName = 'WorkBook', headerColorInHex = '', headerTextColorInHex = '') {
                let workbook = new this.$exceljs.Workbook();
                let sheetNames = Object.keys(dataToExport);
                for await (let e of sheetNames) {
                    let sheetName = e.replace(/_/g, ' ');
                    let worksheet = workbook.addWorksheet(sheetName);
                if(dataToExport[e].headers && dataToExport[e].data) {
                    worksheet.columns = dataToExport[e].headers.filter(h => {
                        if (h.hasOwnProperty('include')) {
                            if (h.include) return h
                        } else {
                            return h
                        }
                    });
                    if (dataToExport[e].hasOwnProperty('options')) {
                        let sheetOptions = Object.keys(dataToExport[e].options)
                        for await (let o of sheetOptions) {
                            worksheet[o] = dataToExport[e].options[o]
                        }
                    }
                    let headerRow = worksheet.getRow(1);
                    headerRow.font = { bold: true };
                    if (headerColorInHex) {
                        for (let c = 1; c <= worksheet.columns.length; c++) {
                            let currentCell = headerRow.getCell(c);
                            currentCell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: headerColorInHex },
                            }
                            if(headerTextColorInHex){
                                currentCell.font = {
                                    color: { argb: headerTextColorInHex },
                                }
                            }
                        }
                    }

                    let headersToFormat = dataToExport[e].headers.filter(e => e.hasOwnProperty('formatAs'))
                    if (headersToFormat.length) {
                        dataToExport[e].data.forEach(record => {
                            headersToFormat.forEach(format => {
                                switch (format.formatAs) {
                                    case 'int':
                                        record[format.key] = record[format.key] ? (isNaN(record[format.key]) ? record[format.key] : parseInt(record[format.key])) : 0;
                                        break;
                                    case 'float':
                                        record[format.key] = record[format.key] ? (isNaN(record[format.key]) ? record[format.key] : parseFloat(record[format.key])) : 0;
                                        break;
                                    case 'date':
                                        record[format.key] = this.formatDate(record[format.key]);
                                        break;
                                    case 'datetime':
                                        record[format.key] = this.formatDateandTime(record[format.key]);
                                        break;
                                }
                            })
                        });
                    }

                    let pos = 1
                    for await (let item of dataToExport[e].data) {
                        worksheet.addRow(item)

                        if (dataToExport[e].hasImages) {
                            await this.addImageToWorksheet(workbook, worksheet, item, pos)
                        }

                        pos++
                    }

                    let columnsToResize = dataToExport[e].headers.filter(e => e.hasOwnProperty('autoResizeColumn'))
                    if (columnsToResize.length) {
                        columnsToResize.forEach((resize, i) => {
                            if (resize.autoResizeColumn) {
                                let maxLength = 0
                                const col = worksheet.getColumn(resize.key)
                                let colHeaderLength = col.header.length > 15 ? col.header.length : 15 //1 character = 6 pixels in Excel

                                col.eachCell({ includeEmpty: false }, function (cell) {
                                    var columnLength = cell.value ? cell.value.toString().length : colHeaderLength;
                                    if (columnLength > maxLength ) {
                                        maxLength = columnLength;
                                    }
                                })
                                col.width = maxLength < colHeaderLength ? colHeaderLength : maxLength
                            }
                        })
                    }
                }
                    if (dataToExport[e].mergeCells) {
                        let merge = dataToExport[e].mergeCells;

                        worksheet.mergeCells(merge.range);
                        // Default HEADERS
                        let title = merge.title 
                            ? merge.title
                            : dataToExport[e].headers.map(h => h.header).join(' | ');

                        let cell = worksheet.getCell(merge.cell);
                        cell.value = title;
                        cell.font = merge.font || { size: 14, bold: true };
                        cell.alignment = merge.alignment || { horizontal: 'center' };
                        cell.fill = merge.fill || { type: 'pattern', pattern: 'solid', fgColor: { argb: headerColorInHex } };
                    }

                    if (dataToExport[e].customRows && Array.isArray(dataToExport[e].customRows)) {
                        dataToExport[e].customRows.forEach(row => {
                            worksheet.addRow([row.header, row.key]);
                        });
                    }
                }
                await workbook.xlsx.writeBuffer().then((data) => {
                    const blob = new Blob([data], { type: 'applicationi/xlsx' });
                    this.$filesaver.saveAs(blob, `${workbookName}.xlsx`);
                })
            },
            forceUpperCaseText(event) {
                const key = event.key;
                if (key.length === 1) {
                    event.preventDefault();
                    const uppercaseKey = key.toUpperCase();
                    const start = event.target.selectionStart;
                    const end = event.target.selectionEnd;
                    const value = event.target.value;

                    const newValue =
                        value.slice(0, start) +
                        uppercaseKey +
                        value.slice(end);

                    this.$nextTick(() => {
                        event.target.value = newValue;
                        event.target.setSelectionRange(start + 1, start + 1);
                        // Emit input event to update the v-model
                        event.target.dispatchEvent(new Event('input'));
                    });
                }
            },
            validateRecognitionMonth(evt, array, index){
                evt.preventDefault()
                let pastedText = evt.clipboardData.getData("Text")
                const regex = /^\d{4}-\d{2}$/
                if (regex.test(pastedText)) {
                    this.$set(array, index, {...array[index], recognition_month: pastedText});
                } else {
                    Swal.fire('', "Invalid date format. Please use 'YYYY-MM' format.", 'error')
                }
            },
            loadImage(src) {
                return new Promise((resolve) => {
                    let img = new Image();
                    img.src = src;
                    img.onload = () => resolve(img);
                });
            },
            getAspectRatio(width, height) {
                var divisor = this.greatestCommonDenaminator(width, height);
                var simplifiedWidth = width / divisor;
                var simplifiedHeight = height / divisor;

                // List of common aspect ratios to compare against
                var commonRatios = [
                    [1, 1],
                    [4, 3],
                    [3, 2],
                    [16, 9],
                    [21, 9]
                ];

                // Calculate the actual ratio
                var ratio = simplifiedWidth / simplifiedHeight;

                // Find the closest common ratio
                var closestRatio = commonRatios[0];
                var smallestDifference = Math.abs(ratio - (commonRatios[0][0] / commonRatios[0][1]));

                for (var i = 1; i < commonRatios.length; i++) {
                    var commonRatio = commonRatios[i];
                    var commonRatioValue = commonRatio[0] / commonRatio[1];
                    var difference = Math.abs(ratio - commonRatioValue);

                    if (difference < smallestDifference) {
                        smallestDifference = difference;
                        closestRatio = commonRatio;
                    }
                }

                // Return the closest ratio
                return closestRatio[0] + ":" + closestRatio[1];
            },
            greatestCommonDenaminator(a, b) {
                while (b) {
                    var temp = b;
                    b = a % b;
                    a = temp;
                }
                return a;
            },
            countingNumberOnly ($event) {
                let keyCode = $event.keyCode ? $event.keyCode : $event.which;
                let inputValue = $event.target.value;
                if ((inputValue === '' && keyCode === 48) || keyCode === 45 || keyCode < 48 || keyCode > 57) {
                    $event.preventDefault();
                }
            },
            async getSystemPlatform() {
                await this.$store.dispatch('systemGet', {url:'get-system-theme'}).then((res)=>{
                    if(res.length > 0){
                        this.$store.commit('SYSTEM_THEME', res[0])
                    }
                });
            },
            async getCompanyDetails(modules=[], companyCode) {
                let data = [];
                let payload = {
                    module: modules,
                    companyCode: companyCode,
                    url:'company-details'
                }
                await this.$store.dispatch('serviceGet', payload).then( response => {
                    data =  response.data;
                }).catch( e => {
                    console.log(e)
                })
                return data;
            },
            formatToExcelDate(value) {
                    if (!value) {
                        return ''
                    }
                return new Date(value);
            },
            async loadDropDown(url){
                return await new Promise((resolve,reject)=>{
                    this.$store.dispatch('dropdownGet',{
                        url:url
                    }).then(response=>{
                        resolve(response.data)
                    })
                })
            },
            limitNumberInput($event, digit){

                let keyCode = $event.keyCode || $event.which;
                let inputValue = $event.target.value;

                // Allow Ctrl+V or Cmd+V
                if ($event.ctrlKey || $event.metaKey) {
                    return;
                }

                // Allow only numeric input and certain keys
                if (
                    (keyCode < 48 || keyCode > 57) && // Numbers 0-9
                    (keyCode < 96 || keyCode > 105) && // Numpad 0-9
                    keyCode != 8 && // Backspace
                    keyCode != 9 && // Tab
                    keyCode != 37 && // Left arrow
                    keyCode != 39 // Right arrow
                ) {
                    $event.preventDefault();
                }

                // Limit the number of digits
                if (
                    inputValue.length >= digit &&
                    ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105))
                ) {
                    $event.preventDefault();
                }
            },
            getAdminLocations(company_id = null){
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('getAdminLocationSelection', {company_id: company_id}).then(response=>{
                        resolve(response)
                    }, error => {
                        reject(error)
                    })
                })
            },
            numberandDotOnly($event) {
                const inputValue = $event.target.value;
                const key = $event.key;

                // Allow navigation keys and backspace/delete
                if (
                    key === "Backspace" || key === "Delete" ||
                    key === "ArrowLeft" || key === "ArrowRight"
                ) {
                    return; // Allow backspace, delete, and arrow keys
                }

                // Allow digits and one dot
                if (key >= "0" && key <= "9") {
                    return; // Allow digits
                } else if (key === ".") {
                    // Prevent multiple dots
                    if (inputValue.includes(".")) {
                        $event.preventDefault();
                    }
                    return;
                }

                // Prevent any other key that is not a digit or dot
                $event.preventDefault();
            },
            currencyWithoutSign(amount, decimal = 2){
                return this.thousandSeprator(parseFloat(amount).toFixed(decimal));
            },
            removeCommaOnAmount(amount){
                amount = amount.replace(/,/g, '')
                return parseFloat(amount).toFixed(2)
            },
            parseNumberString(value, type = 'Float') {
                value = [undefined, null, ''].includes(value) ? 0 : value
                let result = value.toString().replace(/,/g, '').trim()

                return isNaN(result) ? 0 : window[`parse${type}`](result)
            },
            formatDateTimeDuration(startDate, endDate, includedParams = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second']) {
                let result = ''

                if (this.$dayjs(startDate).isValid() && this.$dayjs(endDate).isValid()) {
                    let duration = this.$dayjs.duration(this.$dayjs(startDate).diff(this.$dayjs(endDate)))

                    for (let i of includedParams) {
                        let diff = Math.abs(duration[`${i}s`]())
                        if (diff > 0) result += diff.toString() + ` ${i}s `
                    }
                } else {
                    result = 'Invalid Start/End Date!'
                }

                return result.trim()
            },
            centerTextInJsPdfPrint(textWidth,startPoint,endPoint){
                let centerX = startPoint + (endPoint - startPoint) / 2;
                let textX = centerX - (textWidth / 2);
                return textX;
            },
            getWorkingDaysWithoutSundays(startDate, endDate) {
                const start = new Date(startDate);
                const end = new Date(endDate);

                let workingDays = 0;

                for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
                    if (date.getDay() !== 0) {
                        workingDays++;
                    }
                }

                return workingDays;
            },
            onScroll(e) {
                this.windowTop = e.target.scrollTop
            },
            // placement = top, bottom, left, right | activator to get position of the element
            getNudgePositionWithScroll(placement, activator) {
                let element = activator;
                if (element) {
                    let rect = element.getBoundingClientRect();
                    let elementBottom = rect.bottom
                    switch(placement){
                        case 'top':
                            return rect;
                        case 'bottom':
                            return this.windowTop + elementBottom + 10;
                        case 'left':
                            return rect;
                        case 'right':
                            return rect;
                    }
                }
            },
        }
    }
</script>
