import repository from '@/api/modules/Admin/adminFasBasket.js'
export default {
	state: {
        admin_fasbasket_dispatch:{},
        admin_fasbasket_status:null,
        new_admin_fasbasket:false,
        edit_admin_fasbasket:{},
        edit_fas_basket:{},
        fas_basket:{},
        fas_basket_print:false,
        fas_add_types:'',
        admin_fasbasket_items:[]
	},
	getters: {
      GET_ADMIN_FASBASKET_DISPATCH:(state)=> state.admin_fasbasket_dispatch,
      GET_ADMIN_FASBASKET_STATUS:(state)=> state.admin_fasbasket_status,
      GET_NEW_ADMIN_FASBASKET:(state)=> state.new_admin_fasbasket,
      GET_EDIT_ADMIN_FASBASKET:(state)=> state.edit_admin_fasbasket,
      GET_EDIT_FAS_BASKET:(state)=> state.edit_fas_basket,
      GET_PRINT_FAS_BASKET_DETAIL:(state)=>state.fas_basket,
      GET_PRINT_FAS_BASKET:(state)=>state.fas_basket_print,
      GET_ADMIN_FASBASKET_ITEMS:(state)=>state.admin_fasbasket_items
	},
	actions:{
         createFasBasket({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.createFasBasket(payload).then((response)=>{
                    commit('NEW_ADMIN_FASBASKET',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         updateFasBasket({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.updateFasBasket(payload).then((response)=>{
                    commit('NEW_ADMIN_FASBASKET',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
          },
          deleteFasBasket({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.deleteFasBasket(payload).then((response)=>{
                    commit('NEW_ADMIN_FASBASKET',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
          },
          getAllFasBasket({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getAllFasBasket(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
         },
         showFasBasket({commit},payload){
             return new Promise((resolve,reject)=>{
                 repository.showFasBasket(payload).then((response)=>{
                     commit('EDIT_ADMIN_FASBASKET',response.data);
                     resolve(response);
                    }).catch((err)=>{
                        reject(err);
                    })
                })
            },
            saveImportPrice({commit},payload){
                return new Promise((resolve,reject)=>{
                    repository.saveImportPrice(payload).then((response)=>{
                        resolve(response);
                    }).catch((err)=>{
                    reject(err);
                })
            })
        },
        getItemBasketCanvassing({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getItemBasketCanvassing(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
        approveAllFasBasket({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.approveAllFasBasket(payload).then((response)=>{
                    commit('NEW_ADMIN_FASBASKET',true)
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
        getAllVendorPriority({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getAllVendorPriority(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
        updateAllVendorPriority({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.updateAllVendorPriority(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
        getSelectedItemBasketCanvassing({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.getSelectedItemBasketCanvassing(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
        purchaseBasketGet({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.purchaseBasketGet(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
        purchaseBasketPost({commit},payload){
            return new Promise((resolve,reject)=>{
                repository.purchaseBasketPost(payload).then((response)=>{
                    resolve(response);
                }).catch((err)=>{
                    reject(err);
                })
            })
        },
	},
	mutations:{
        ADMIN_FASBASKET_DISPATCH:(state,data)=>{
            state.admin_fasbasket_dispatch = data
        },
        ADMIN_FASBASKET_STATUS:(state,data)=>{
            state.admin_fasbasket_status = data
        },
        NEW_ADMIN_FASBASKET:(state,data)=>{
            state.new_admin_fasbasket = data
        },
        EDIT_ADMIN_FASBASKET:(state,data)=>{
            state.edit_admin_fasbasket = data
        },
        EDIT_FAS_BASKET:(state,data)=>{
            state.edit_fas_basket = data
        },
        PRINT_FAS_BASKET_DETAIL:(state,data)=>{
            state.fas_basket = data
        },
        PRINT_FAS_BASKET:(state,data)=>{
            state.fas_basket_print = data
        },
        FAS_ADD_TYPES:(state,data)=>{
            state.fas_add_types = data
        },
        ADMIN_FASBASKET_ITEMS:(state,data)=>{
            state.admin_fasbasket_items = data
        }
    },
}
