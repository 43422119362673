import repository from '@/api/modules/MasterData'

export default {
  state: {
    vehicles: [],
    selected_vehicles: [],
    new_vehicles: false,
    vehicle_loaded: false,
    vehicle_types:[],
    vehicles_type: [],
    selected_vehicles_type: [],
    new_vehicles_type: false,
    vehicle_loaded_type: false,
    vehicle_orcr: false,
  },
  getters: {
    GET_VEHICLES_LIST: state => state.vehicles,
    GET_SELECTED_VEHICLE: state => state.selected_vehicles,
    NEW_VEHICLES: state => state.new_vehicles,
    GET_VEHICLES_LOADED: state => state.vehicle_loaded,
    GET_VEHICLES_TYPE_LIST: state => state.vehicles_type,
    GET_SELECTED_VEHICLE_TYPE: state => state.selected_vehicles,
    NEW_VEHICLES_TYPE: state => state.new_vehicles,
    GET_VEHICLES_LOADED_TYPE: state => state.vehicle_loaded,
    GET_VEHICLE_TYPES: state => state.vehicle_types,
    GET_VEHICLE_ORCR: state => state.vehicle_orcr,
  },
  actions: {
    async getVehicles({ commit },payload) {
      return await new Promise((resolve,reject)=>{
        repository.vehicleslist(payload).then(response => {
          commit('VEHICLES_LIST', response.data);
          commit('VEHICLES_LOADED', true)
          resolve(response)
        }).catch(error=>{
          reject(error);
        });
      })

    },
    addNewVehicles({ commit }, payload) {
      repository.addvehicle(payload).then(() => {
        commit('NEW_VEHICLES', true);
      })
    },
    UpdateVehicles({ commit }, payload) {
      const params = {
        id: payload.id,
        name: payload.payload.name,
        code: payload.payload.code,
        brand: payload.payload.brand,
        type: payload.payload.type,
        plate_number: payload.payload.plate_number,
        lengths: payload.payload.lengths,
        width: payload.payload.width,
        height: payload.payload.height,
        cbm: payload.payload.cbm,
        tpl:payload.payload.tpl,
        ending: payload.payload.ending,
        conduction_sticker: payload.payload.conduction_sticker,
        company_id: payload.payload.company_id,
        owner_name: payload.payload.owner_name,
        year_model: payload.payload.year_model,
        color: payload.payload.color,
        accounted_to: payload.payload.accounted_to,
        admin_location_id: payload.payload.admin_location_id,
        certification_of_registration_number: payload.payload.certification_of_registration_number,
        date_of_certification_of_registration_number: payload.payload.date_of_certification_of_registration_number,
        admin_purchase_order_id: payload.payload.admin_purchase_order_id,
        official_receipt_number: payload.payload.official_receipt_number,
        latest_official_receipt_date: payload.payload.latest_official_receipt_date,
        engine_number: payload.payload.engine_number,
        serial_number: payload.payload.engine_number,
        mv_filer_number: payload.payload.mv_filer_number,
        fuel: payload.payload.fuel,
        remarks: payload.payload.remarks,
        keys: payload.payload.keys,
        rfid_account_number: payload.payload.rfid_account_number,
        department_id: payload.payload.department_id,
        unit_description:payload.payload.unit_description,
        expense_company_id:payload.payload.expense_company_id,
        purchase_amount:payload.payload.purchase_amount,
        purchase_date:payload.payload.purchase_date,
        purchase_year:payload.payload.purchase_year,
        code_name:payload.payload.code_name,
        admin_info_remarks:payload.payload.admin_info_remarks,
        policies:payload.payload.policies,
        address:payload.payload.address,
        files:payload.payload.files,
        orcr_expiration:payload.payload.orcr_expiration,
        rfid_easy_trip:payload.payload.rfid_easy_trip,
        rfid_auto_sweep:payload.payload.rfid_auto_sweep,
      };
      repository.updatevehicles(params).then(response => {
        commit('NEW_VEHICLES', true);
      })
    },
    deletevehicles({ commit }, payload) {
      repository.deletevehicles(payload).then(response => {
        commit('DELETED_VEHICLES', payload);
      })
    },
    getVehiclesType({ commit },payload) {
      new Promise((resolve,reject)=>{
        repository.vehicleslisttype(payload).then(response => {
          commit('VEHICLES_TYPE_LIST', response.data);
          commit('VEHICLES_LOADED_TYPE', true)
        }).catch(error=>{
          reject(error);
        });
      })
    },
    addNewVehiclesType({ commit }, payload) {
      repository.addvehicletype(payload).then(() => {
        commit('NEW_VEHICLES_TYPE', true);
      })
    },
    UpdateVehiclesType({ commit }, payload) {
      const params = {
        id: payload.id,
        name: payload.payload.name,
        code: payload.payload.code,
      };
      repository.updatevehiclestype(params).then(response => {
        commit('NEW_VEHICLES_TYPE', true);
      })
    },
    deletevehiclestype({ commit }, payload) {
      repository.deletevehiclestype(payload).then(response => {
        commit('DELETED_VEHICLES_TYPE', payload);
      })
    },
    getAllVehicleTypes({ commit }, payload) {
      repository.getvehicletypes(payload).then(response => {
        commit('VEHICLE_TYPES', response.data);
      })
    },
    getVehicleORCR({ commit }, payload) {
      return new Promise((resolve,reject)=>{
          repository.getVehicleORCR(payload).then((response) => {
            commit('VEHICLE_ORCR', response.data);
            resolve(response)
          }).catch((error)=>{
            reject(error)
          });
      })
    },
    async vehiclePolicyGet({ commit },payload) {
      return await new Promise((resolve,reject)=>{
        repository.vehiclePolicyGet(payload).then(response => {
          resolve(response)
        }).catch(error=>{
          reject(error);
        });
      })
    },
    async vehiclePolicyPost({ commit },payload) {
      return await new Promise((resolve,reject)=>{
        repository.vehiclePolicyPost(payload).then(response => {
          resolve(response)
        }).catch(error=>{
          reject(error);
        });
      })
    },
    async vehicleGet({ commit },payload) {
      return await new Promise((resolve,reject)=>{
        repository.vehicleGet(payload).then(response => {
          resolve(response)
        }).catch(error=>{
          reject(error);
        });
      })

    },
    async vehiclePost({ commit },payload) {
      return await new Promise((resolve,reject)=>{
        repository.vehiclePost(payload).then(response => {
          resolve(response)
        }).catch(error=>{
          reject(error);
        });
      })
    },
  },
  mutations: {
    VEHICLES_LIST: (state, data) => {
      state.vehicles = data;
    },
    NEW_VEHICLES: (state, data) => {
      state.new_vehicles = data;
    },
    SELECTED_VEHICLE: (state, data) => {
      state.selected_vehicles = data;
    },
    EDITED_VEHICLES: (state, payload) => {
      const index = state.vehicles.findIndex(t => t.id == payload.id);
      state.vehicles.splice(index, 1, payload);
    },
    DELETED_VEHICLES: (state, id) => {
      state.vehicles = state.vehicles.filter(vehicles => vehicles.id !== id);
    },
    VEHICLES_LOADED: (state, data) => {
      state.vehicle_loaded = data;
    },

    VEHICLES_TYPE_LIST: (state, data) => {
      state.vehicles_type = data;
    },
    NEW_VEHICLES_TYPE: (state, data) => {
      state.new_vehicles_type = data;
    },
    SELECTED_VEHICLE_TYPE: (state, data) => {
      state.selected_vehicles_type = data;
    },
    EDITED_VEHICLES_TYPE: (state, payload) => {
      const index = state.vehicles_type.findIndex(t => t.id == payload.id);
      state.vehicles_type.splice(index, 1, payload);
    },
    DELETED_VEHICLES_TYPE: (state, id) => {
      state.vehicles_type = state.vehicles_type.filter(vehicles => vehicles.id !== id);
    },
    VEHICLES_LOADED_TYPE: (state, data) => {
      state.vehicle_loaded = data;
    },

    VEHICLE_TYPES: (state, data) => {
      state.vehicle_types = data;
    },

    VEHICLE_ORCR: (state, data) => {
      state.vehicle_orcr = data;
    },
  },
};
